import React from "react";
import PropTypes from "prop-types";



const BlogPostContent = ({ content }) => {
	return (
		<section className="article-content">
			<div className="article-content__container">
				<div
					className="rte"
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			</div>
		</section>
	);
};

BlogPostContent.propTypes = {
	content: PropTypes.string.isRequired
};

export default BlogPostContent;

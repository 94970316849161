import React from "react";
import PropTypes from "prop-types";

import PostCard from "../../components/PostCard";

const BlogPostRelatedPosts = ({ subtitle, title, posts }) => {
	if (!posts.length) {
		return null;
	}

	return (
		<section className="article-related">
			<div className="container container--medium">
				{subtitle &&
					<p className="article-related__label">
						{subtitle}
					</p>
				}

				{title &&
					<h2 className="section-title article-related__title">
						{title}
					</h2>
				}

				<div className="article-related__list blog-posts-list">
					{posts.map(({ id, title, date, slug, categories, link, acfPost }) => {
						const {
							excerpt,
							squaredImage
						} = acfPost;

						return (
							<div
								key={id}
								className="blog-posts-list__item"
							>
								<PostCard
									titleTag={"h3"}
									title={title}
									categories={categories}
									date={date}
									link={link}
									squaredImage={squaredImage}
									excerpt={excerpt}
									isRow={true}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

BlogPostRelatedPosts.propTypes = {
	subtitle: PropTypes.string,
	title: PropTypes.string,
	posts: PropTypes.array.isRequired
};

export default BlogPostRelatedPosts;
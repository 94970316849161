import React from "react";
import PropTypes from "prop-types";
import BlogPostAccordion from "./BlogPostAccordion";
const BlogPostAccordions = ({ accordion, title }) => {
	if (!accordion?.length) return;

	return (
		<section className="article-accordions">
			<div className="article-accordions__container">
				{title &&
					<h2 className="article-accordions__heading">
						{title}
					</h2>
				}

				{accordion.map(({title, body}, index) => {
					return (
						<BlogPostAccordion title={title} body={body} key={index}/>
					)
				})}
			</div>
		</section>
	);
};

BlogPostAccordions.propTypes = {
	title: PropTypes.string,
	accordion: PropTypes.array.isRequired
};

export default BlogPostAccordions;

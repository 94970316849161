import React from "react";
import PropTypes from "prop-types";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import IconChevronLeft from "../../icons/IconChevronLeft";

const BlogPostSlider = ({ slides, sliderCaption }) => {
	SwiperCore.use([Navigation]);

	const sliderParams = {
		slidesPerView: 1,
		loop: true,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev"
		}
	};

	return (
		<section className="article-slider">
			<div className="article-slider__container">
				<Swiper
					className="article-slider__slider"
					{...sliderParams}
				>
					{slides.map(({ image, slideCaption }, index) => {
						const gatsbyImageData = image.localFile?.childImageSharp?.gatsbyImageData;

						if (!gatsbyImageData) {
							return null;
						}

						const backgroundImage = convertToBgImage(gatsbyImageData);

						return (
							<SwiperSlide
								className="article-slider__slide"
								key={index}
							>
								<BackgroundImage
									Tag="div"
									className="article-slider__image"
									{...backgroundImage}
								/>

								{slideCaption &&
									<p className="article-slider__caption">
										{slideCaption}
									</p>
								}
							</SwiperSlide>
						);
					})}

					<button className="swiper-button-prev">
						<IconChevronLeft/>
					</button>

					<button className="swiper-button-next">
						<IconChevronLeft/>
					</button>
				</Swiper>

				{sliderCaption &&
					<p className="article-slider__caption">
						{sliderCaption}
					</p>
				}
			</div>
		</section>
	);
}

BlogPostSlider.propTypes = {
	slides: PropTypes.array.isRequired,
	sliderCaption: PropTypes.string
};

export default BlogPostSlider;

import React from "react";
import PropTypes from "prop-types";

const BlogPostSummary = ({ title, text }) => {
	return (
		<section className="article-summary">
			<div className="article-summary__container">
				<div className="article-summary__content">
					{title &&
						<h2 className="article-summary__title">
							{title}
						</h2>
					}

					<div
						className="article-summary__text"
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				</div>
			</div>
		</section>
	);
};

BlogPostSummary.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string.isRequired
};

export default BlogPostSummary;
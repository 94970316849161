import React from "react";
import PropTypes from "prop-types";

import IconChevronLeft from "../../icons/IconChevronLeft";
import IconClock from "../../icons/IconClock";
import IconRocket from "../../icons/IconRocket";
import {Link} from "gatsby";

const BlogPostMeta = ({date, allCategory, timeToRead, levels}) => {
    const postLevels = levels?.nodes ?? [];

    return (
        <section className="article-meta">
            <div className="container container--medium">
                <div className="article-meta__wrapper">
                    <div className="article-meta__row">
                        <ul className="article-meta__info">
                            <li className="article-meta__info-item">
                                <Link to="/">
                                    <IconChevronLeft/>
                                    Home
                                </Link>
                            </li>
                            {allCategory.title && allCategory.link &&
                                <li className="article-meta__info-item">
                                    <Link to={allCategory.link + '/'}>
                                        {allCategory.title}
                                    </Link>
                                </li>
                            }

                            <li className="article-meta__info-item">
                                {date}
                            </li>
                        </ul>

                        {(timeToRead || postLevels.length > 0) &&
                            <div className="article-meta__stats">
                                {timeToRead &&
                                    <div className="icon-group article-meta__stats-item">
                                        <IconClock/><span className="visually-hidden">Time to read</span>

                                        <span className="icon-group article-meta__stats-item-value">
											{timeToRead}
										</span>
                                    </div>
                                }

                                {postLevels.length > 0 &&
                                    <div className="icon-group article-meta__stats-item">
                                        <IconRocket/><span className="visually-hidden">Difficulty level</span>

                                        {postLevels.map((level, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className="icon-group article-meta__stats-item-value"
                                                >
													{level.name}
												</span>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

BlogPostMeta.propTypes = {
    date: PropTypes.string.isRequired,
    timeToRead: PropTypes.string
};

export default BlogPostMeta;

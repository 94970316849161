import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import DefaultHelmet from "../seo/DefaultHelmet";

import BlogPostBanner from "../sections/BlogPost/BlogPostBanner";
import BlogPostMeta from "../sections/BlogPost/BlogPostMeta";
import BlogPostContent from "../sections/BlogPost/BlogPostContent";
import BlogPostSummary from "../sections/BlogPost/BlogPostSummary";
import BlogPostSlider from "../sections/BlogPost/BlogPostSlider";
import BlogPostAccordions from "../sections/BlogPost/BlogPostAccoordions";
import BlogPostRelatedPosts from "../sections/BlogPost/BlogPostRelatedPosts";
import Newsletter from "../components/Newsletter";
import BlogPostShare from "../sections/BlogPost/BlogPostShare";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";
import {graphql, useStaticQuery} from "gatsby";

const BlogPost = ({ pageContext, ...props }) => {
	const {
		blogPost,
		allCategory,
		relatedPosts
	} = pageContext;

	const {
		title,
		date,
		categories,
		levels,
		page_header: pageHeader,
	} = blogPost;

	const {
		banner,
		timeToRead,
		sections,
		excerpt,
		metaData
	} = blogPost.acfPost;

	const getSection = ({ fieldGroupName, ...section }, index) => {
		const key = index;

		switch (fieldGroupName) {
			case "post_Acfpost_Sections_ArticleRichTextContent": {
				return <BlogPostContent
					key={key}
					{...section}
				/>
			}

			case "post_Acfpost_Sections_ArticleSummary": {
				return <BlogPostSummary
					key={key}
					{...section}
				/>
			}

			case "post_Acfpost_Sections_ArticleSlider": {
				return <BlogPostSlider
					key={key}
					{...section}
				/>
			}

			case "post_Acfpost_Sections_ArticleAccordions": {
				return <BlogPostAccordions
					key={key}
					{...section}
				/>
			}

			default: {
				return null;
			}
		}
	};

	const getSections = () => {
		if (!Array.isArray(sections)) {
			return null;
		}

		return sections.map(getSection);
	};

	const pageTitle = metaData?.title || title;
	const pageDescription = metaData?.description || `UTD Blog: ${title}: The latest news from world of eCommerce`;
	const pageKeyword = "UTD, UTD Blog, eCommerce, eCommerce news"

	useBeaconWidget(beaconWidgetIds.GENERAL);

	const {site} = useStaticQuery(
		graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                        title
                        author
                    }
                }
            }
        `
	);

	const microdata = {
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		"headline": title,
		"image": [
			site?.siteMetadata?.siteUrl + blogPost.acfPost.squaredImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
		],
		"datePublished": date,
		"author": [{
			"@type": "Organization",
			"name": site?.siteMetadata?.title,
			"url": site?.siteMetadata?.siteUrl
		}]
	};

	return (
		<DefaultLayout>
			<DefaultHelmet title={pageTitle} description={pageDescription} keywords={pageKeyword} microdata={microdata}/>

			<BlogPostBanner
				blogPostTitle={title}
				pageHeader={pageHeader}
				{...banner}
			/>

			<BlogPostMeta
				date={date}
				categories={categories}
				allCategory={allCategory}
				timeToRead={timeToRead}
				levels={levels}
			/>

			{getSections()}

			<BlogPostShare
				link={props.location.href}
				text={title}
				longText={excerpt}
			/>

			<div className="article-newsletter">
				<Newsletter />
			</div>

			<BlogPostRelatedPosts
				{...relatedPosts}
			/>
		</DefaultLayout>
	)
};

export default BlogPost;

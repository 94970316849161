import React from "react";
import classNames from "classnames";
import {useRef, useState} from "react";
const BlogPostAccordion = ({title, body, key}) => {
    const accordionBody = useRef(null);
    const [isAccordionOpened, setIsAccordionOpened] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const toggleAccordion = () => {
        setIsButtonDisabled(true);
        const accordionBodyElement = accordionBody.current;
        accordionBodyElement.style.maxHeight = accordionBodyElement.scrollHeight + "px";

        setTimeout(() => {
            accordionBodyElement.style.maxHeight = (isAccordionOpened ? 0 : accordionBodyElement.scrollHeight) + "px";
            setIsAccordionOpened(!isAccordionOpened);
        });

        setTimeout(() => {
            setIsButtonDisabled(false);
            if (isAccordionOpened) return;
            accordionBodyElement.style.maxHeight = "";
        }, 500);
    };

    const accordionClasses = classNames({
        "article-accordion": true,
        "article-accordion--active": isAccordionOpened
    });

    const buttonClasses = classNames({
        "article-accordion__button": true,
        "article-accordion__button--active": isAccordionOpened
    });

    const bodyClasses = classNames({
        "article-accordion__body": true,
        "article-accordion__body--active": isAccordionOpened
    });

    return (
        <div className={accordionClasses} key={key}>
            <h3 className="article-accordion__heading">
                <button className={buttonClasses} onClick={toggleAccordion} disabled={isButtonDisabled}>
                    {title}
                </button>
            </h3>

            <div className={bodyClasses} ref={accordionBody} style={{maxHeight: 0}}>
                <div className="rte" dangerouslySetInnerHTML={{ __html: body }}>
                </div>
            </div>
        </div>
    );
}

export default BlogPostAccordion;

import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import PageHeader from "../../components/PageHeader";

const BlogPostBanner = ({ blogPostTitle, title, showTitle, image, pageHeader }) => {
	const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData;
	const backgroundImage = gatsbyImageData ? convertToBgImage(gatsbyImageData) : null;

	const headerOnBanner = pageHeader?.position === 'on';
	const headerBelowBanner = pageHeader?.position === 'below' || pageHeader?.position === 'bellow';
	const bannerTitleProps = headerOnBanner && pageHeader?.title
		? pageHeader
		: {};

	const bannerTitleMarkup = (
		<PageHeader
			{...bannerTitleProps}
			title={headerOnBanner && pageHeader?.title
				? pageHeader?.title
				: showTitle && (title || blogPostTitle)
		}
			implemented={true}
			className={"section-title article-banner__title"}
			isGeneral={headerOnBanner}
		/>
	);

	const titleBelowBannerMarkup = headerBelowBanner && pageHeader?.title
		? <PageHeader {...pageHeader} isGeneral={true} />
		: null;

	return (
		backgroundImage ?
			<>
				<BackgroundImage
					Tag="section"
					className="article-banner"
					{...backgroundImage}
				>
					<div className="article-banner__overlay"/>

					<div className="article-banner__content">
						<div className="container container--medium">
							{bannerTitleMarkup}
						</div>
					</div>
				</BackgroundImage>

				{titleBelowBannerMarkup}
			</> :
			<>
				<div className="article-banner">
					<div className="article-banner__overlay"/>

					<div className="article-banner__content">
						<div className="container container--medium">
							{bannerTitleMarkup}
						</div>
					</div>
				</div>

				{titleBelowBannerMarkup}
			</>
	);
};

BlogPostBanner.propTypes = {
	blogPostTitle: PropTypes.string.isRequired,
	title: PropTypes.string
};

export default BlogPostBanner;

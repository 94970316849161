import React from "react";
import PropTypes from "prop-types";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon,
    LinkedinShareButton,
} from "react-share";
import {graphql, useStaticQuery} from "gatsby";

const BlogPostShare = ({link, text, longText}) => {
    const {allWp} = useStaticQuery(graphql`
		query PostShare {
			allWp {
				nodes {
					shareButtons {
						acfShareButtons {
							showShareButtons
						}
					}
				}
			}
		}
	`);

    const {
        showShareButtons
    } = allWp.nodes?.[0]?.shareButtons?.acfShareButtons || {};

    if (!showShareButtons) {
        return null;
    }

    return (
        <section className="article-share container container--small">
            <div className="article-share__wrapper">
                <div className="article-share__buttons">
                    <FacebookShareButton url={link} aria-label="Share in Facebook">
                        Share
                        <FacebookIcon size={32} round={true}/>
                    </FacebookShareButton>

                    <LinkedinShareButton url={link} aria-label="Share in LinkedIn">
                        Share
                        <LinkedinIcon size={32} round={true}/>
                    </LinkedinShareButton>
                </div>
            </div>
        </section>
    );
};

BlogPostShare.propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    longText: PropTypes.string.isRequired
};

export default BlogPostShare;
